import "./TrustedBy.scss";
import { motion } from "framer-motion";

const TrustedBy = ({ home }) => {
  return (
    <section className="trusted_by p_t">
      <div className="content_wrap">
        <div className="bi_grid">
          <div className="left">
            <motion.h2
              initial={{ opacity: 0, translateY: 60 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
               viewport={{ once: true }}
            >
              {home?.section_7_client_link || "-"}
            </motion.h2>
          </div>
          <div className="trusted_grid">
            {home?.client_images?.map((item, i) => {
              return (
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.4,
                    ease: "easeInOut",
                    delay: 0.1 * i,
                  }}
                   viewport={{ once: true }}
                  className="logo_wrap"
                  key={i}
                >
                  <img src={item} alt="logo" />
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedBy;

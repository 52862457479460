import "./HomeLanding.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";

import { webContext } from "../../../webContext/WebContext";
import { useNavigate } from "react-router-dom";

const HomeLanding = ({home}) => {
  const textTransition = 1;
  const lineA = "Unimaginable.";
  const lineB = "Unthinkable.";
  const lineC = "Unprecedented.";

  const navigate = useNavigate();
  const [slideCount, setSlideCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideCount((prevStatus) => (prevStatus + 1) % 3);
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  const data = useContext(webContext);

  return (
    <section className="home_landing">
      <video autoPlay muted loop alt={home?.video_alt || "Video"}>
        <source src={home?.video} type="video/mp4" />
      </video>
      <div className="content_wrap p_b p_t">
        {/* For desktop */}
        <div className="text_wrap_desktop">
          <div className="slider">
            {slideCount === 0 && (
              <motion.div
                initial={{ translateY: 100 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineA}</h1>
              </motion.div>
            )}

            {slideCount === 1 && (
              <motion.div
                initial={{ translateY: 100 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineB}</h1>
              </motion.div>
            )}

            {slideCount === 2 && (
              <motion.div
                initial={{ translateY: 100 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineC}</h1>
              </motion.div>
            )}
          </div>
        </div>

        {/* For Tab */}
        <div className="text_wrap_tab">
          <div className="slider">
            {slideCount === 0 && (
              <motion.div
                initial={{ translateY: 60 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineA}</h1>
              </motion.div>
            )}

            {slideCount === 1 && (
              <motion.div
                initial={{ translateY: 60 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineB}</h1>
              </motion.div>
            )}

            {slideCount === 2 && (
              <motion.div
                initial={{ translateY: 60 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineC}</h1>
              </motion.div>
            )}
          </div>
        </div>

        {/* For mobile */}

        <div className="text_wrap_mobile">
          <div className="slider">
            {slideCount === 0 && (
              <motion.div
                initial={{ translateY: 40 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineA}</h1>
              </motion.div>
            )}

            {slideCount === 1 && (
              <motion.div
                initial={{ translateY: 40 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineB}</h1>
              </motion.div>
            )}

            {slideCount === 2 && (
              <motion.div
                initial={{ translateY: 40 }}
                whileInView={{ translateY: 0 }}
                transition={{ duration: textTransition, ease: "easeInOut" }}
                  viewport={{ once: true }}
                className="slide"
              >
                <h1>{lineC}</h1>
              </motion.div>
            )}
          </div>
        </div>

        <h1 className="brand">Inauditus</h1>
        <p>{home?.description || "-"}</p>
        <div className="btn_wrap">
          <div className="left">
            <PrimaryBtn title="Get in Touch" clickHandler={() => navigate("/contact")} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeLanding;







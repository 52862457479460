import { images } from "../../../utils/images";
import "./LeftRightSec.scss";
import { motion } from "framer-motion";

const LeftRightSec = ({home}) => {
  return (
    <section className="left_right_sec p_b">
      <div className="content_wrap">
        <div className="bi_grid">
          <div className="text_content">
            <motion.p
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
               viewport={{ once: true }}
              className="t_20"
            >
             {home?.section_5_description || "-"}
            </motion.p>
           
          </div>
          <div className="img_wrap">
            <motion.img
              initial={{ opacity: 0, scale: 0.4 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
               viewport={{ once: true }}
              src={home?.section_5_image}
              alt={home?.section_5_image_alt}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftRightSec;
